<template>
  <b-row align-h="center" v-if="dataLoading">
    <b-spinner
      class="m-5"
      style="width: 4rem; height: 4rem"
      type="grow"
      variant="primary"
      label="Spinning"
    ></b-spinner>
  </b-row>
  <div v-else>
    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility4"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Update</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility4 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1">
          <b-col md="12">
            <b-form-group
              label="Exam"
              invalid-feedback="Exam is required."
              ref="ch_exam"
            >
              <v-select
                ref="ch_exam"
                v-model="termObj.examID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="examType"
                :reduce="(val) => val.id"
                label="examType"
                :clearable="false"
                placeholder="Select exam"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Session"
              invalid-feedback="Session is required."
              ref="session"
            >
              <v-select
                ref="session"
                v-model="termObj.sessionID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="sessions"
                :reduce="(val) => val.id"
                label="session"
                :clearable="false"
                placeholder="Select session"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Date"
              invalid-feedback="date is required."
              ref="ch_date"
            >
              <flat-pickr
                ref="ch_date"
                :config="config"
                v-model="termObj.date"
                class="form-control"
                placeholder="Select Date."
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              class="mr-1"
              @click="change()"
              :disabled="changing"
              block
            >
              <b-spinner v-if="changing" small type="grow" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ sidebarTitle }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideSideBar()"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1">
          <b-col md="12">
            <b-form-group
              label="Exam"
              invalid-feedback="Exam is required."
              ref="bon_exam"
            >
              <v-select
                ref="bon_exam"
                v-model="bonusObj.examID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="examType"
                :reduce="(val) => val.id"
                label="examType"
                :clearable="false"
                placeholder="Select exam"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Type"
              invalid-feedback="Type is required."
              ref="bon_type"
            >
              <v-select
                ref="bon_type"
                v-model="bonusObj.type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="bonusTypes"
                :reduce="(val) => val.value"
                label="text"
                :clearable="false"
                placeholder="Select type."
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              class="mr-1 fullWidth"
              @click="Add()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else> {{ sidebarButton }} </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>
    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility2"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Settings</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility2 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1">
          <b-col md="12">
            <b-form-group
              label="Choose Template"
              invalid-feedback="Template is required."
              ref="temp"
            >
              <v-select
                ref="temp"
                v-model="temp"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="tempOptions"
                :clearable="false"
                label="name"
                :reduce="(val) => val.id"
                @input="setMessage()"
                placeholder="Select template"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Message"
              invalid-feedback="Message is required."
            >
              <b-form-textarea
                disabled
                placeholder="Your message."
                v-model="tempMessage"
                rows="8"
              ></b-form-textarea>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              @click="saveSetting()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else> Save </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-Fee"
      bg-variant="white"
      v-model="sessionbar"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Manage Sessions</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="sessionbar = false"
        />
      </div>

      <b-form class="p-2" @submit.prevent>
        <b-row class="d-flex align-items-center">
          <b-col md="10">
            <b-form-group
              label="Name"
              invalid-feedback="Session name is required."
            >
              <b-form-input
                ref="sess_name"
                v-model="sessionObj.session"
                placeholder="Enter session name here"
                @focusout="SessName()"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-button
              variant="success"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="saveSession()"
              :disabled="savingacc"
            >
              <b-spinner v-if="savingacc" small type="grow" />
              <feather-icon v-else :icon="accIcon" />
            </b-button>
          </b-col>
        </b-row>
        <b-table
          class="mt-1"
          show-empty
          :items="sessions"
          :fields="sessFields"
          responsive
          hover
        >
          <template #head(actions)="data">
            <div class="text-right">
              <span>{{ data.label }}</span>
            </div>
          </template>

          <template #cell(session)="data">
            <b-badge
              variant="light-primary"
              v-if="
                $store.state.currentBranch.organizationType == 'coaching' &&
                data.item.isCurrentSession
              "
            >
              {{ data.item.session }}
            </b-badge>
            <span v-else>{{ data.item.session }}</span>
          </template>

          <template #cell(actions)="data">
            <div class="text-right">
              <b-button
                variant="primary"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="editSess(data.item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                variant="outline-danger"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="deleteSess(data.item)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
              <b-button
                v-if="
                  $store.state.currentBranch.organizationType == 'coaching' &&
                  !data.item.isCurrentSession
                "
                variant="outline-primary"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="setCurrentSess(data.item.id)"
              >
                <feather-icon icon="CheckCircleIcon" />
              </b-button>
            </div>
          </template>
        </b-table>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility3"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Send Messsage</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility3 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="pb-1" style="align-items: center">
          <b-col md="12">
            <b-form-group
              label="Class"
              invalid-feedback="class is required."
              ref="msg_class"
            >
              <v-select
                ref="msg_class"
                multiple
                :closeOnSelect="false"
                v-model="msgClass"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="msgClassOptions"
                :clearable="false"
                label="name"
                :reduce="(val) => val.id"
                @input="FillMsgSections()"
                placeholder="Select class"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" v-if="msgClass[0] != 0">
            <b-form-group
              label="Section"
              invalid-feedback="section is required."
              ref="msg_section"
            >
              <v-select
                multiple
                :closeOnSelect="false"
                v-model="msgSection"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="msgSecOptions"
                :reduce="(val) => val.id"
                :clearable="false"
                @input="checkMsgSections()"
                ref="msg_section"
                label="section"
                placeholder="Select section"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Subject"
              invalid-feedback="Subject is required."
              ref="msg_subject"
            >
              <v-select
                ref="msg_subject"
                multiple
                :closeOnSelect="false"
                v-model="msgObj.subIDs"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="allSubjects"
                :reduce="(val) => val.id"
                label="subject"
                :clearable="false"
                placeholder="Select subject"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Exam Type"
              invalid-feedback="Exam type is required."
              ref="msg_exam"
            >
              <v-select
                ref="msg_exam"
                v-model="msgObj.examID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="examType"
                :reduce="(val) => val.id"
                label="examType"
                :clearable="false"
                placeholder="Select exam type."
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Session"
              invalid-feedback="Session is required."
              ref="msg_session"
            >
              <v-select
                ref="msg_session"
                v-model="msgObj.sessionID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="sessions"
                :reduce="(val) => val.id"
                label="session"
                :clearable="false"
                placeholder="Select session"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Date"
              invalid-feedback="date is required."
              ref="msg_date"
            >
              <flat-pickr
                ref="msg_date"
                :config="config"
                v-model="msgObj.date"
                class="form-control"
                placeholder="Select Date."
              />
            </b-form-group>
          </b-col>
          <b-col class="mt-1" md="12">
            <b-button
              variant="success"
              @click="sendMessage()"
              :disabled="sending"
              block
            >
              <b-spinner v-if="sending" small type="grow" />
              <span v-else>Send Message</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-card>
      <b-row align-h="start" class="text-left mt-1">
        <b-col md="2">
          <b-form-group
            label="Class"
            invalid-feedback="Class is required."
            ref="class"
          >
            <v-select
              v-model="myObj.clsID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="classesOptions"
              :clearable="false"
              label="name"
              :reduce="(val) => val.id"
              input-id="id"
              ref="class"
              :disabled="dataLoading || sheetLoading"
              placeholder="Select class."
              @input="FillSections()"
            />
            <!-- @search:blur="loadRecords()" -->
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group
            label="Section"
            invalid-feedback="section is required."
            ref="sec"
          >
            <v-select
              ref="sec"
              v-model="myObj.secID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="currentSections"
              :reduce="(val) => val.id"
              label="section"
              :clearable="false"
              :disabled="dataLoading || sheetLoading"
              placeholder="Select section."
              @input="LoadSubjects('load')"
            />
            <!-- @search:blur="loadRecords()" -->
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group
            label="Exam Type"
            invalid-feedback="Exam type is required."
            ref="examType"
          >
            <v-select
              ref="examType"
              v-model="myObj.examID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="examType"
              :reduce="(val) => val.id"
              label="examType"
              :clearable="false"
              :disabled="dataLoading || sheetLoading"
              placeholder="Select exam type."
              @input="loadRecords()"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group invalid-feedback="Session is required." ref="session">
            <div class="d-flex justify-content-between">
              <label class="bv-no-focus-ring col-form-label pt-0"
                >Session</label
              >
              <feather-icon
                class="cursor-pointer"
                icon="SettingsIcon"
                size="18"
                v-b-tooltip.hover.top
                title="Sessions"
                @click="openSession()"
              />
            </div>
            <v-select
              ref="session"
              v-model="myObj.sessionID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="sessions"
              :reduce="(val) => val.id"
              label="session"
              :clearable="false"
              :disabled="dataLoading || sheetLoading"
              placeholder="Select session."
              @input="loadRecords()"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-dropdown
            class="mt-2"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            split
            variant="success"
            right
            @click="dataSheet('ShowDatasheet')"
            :disabled="sheetLoading"
          >
            <template #button-content>
              <b-spinner v-if="sheetLoading" small type="grow" />
              <span v-else>DataSheet</span>
            </template>
            <b-dropdown-item @click="dataSheet('MarksOnly')">
              Marks Only
            </b-dropdown-item>
            <b-dropdown-item @click="dataSheet('BlankDatasheet')">
              Blank Datasheet
            </b-dropdown-item>
            <b-dropdown-item @click="dataSheet('summary')">
              Result Summary
            </b-dropdown-item>
            <b-dropdown-item @click="dataSheet('ptm')">
              PTM Summary
            </b-dropdown-item>

            <b-dropdown-divider />
          </b-dropdown>
        </b-col>

        <b-col md="1" class="mt-1" v-if="false">
          <b-button
            variant="outline-primary"
            class="btn-icon"
            @click="openMessage()"
          >
            <feather-icon icon="MailIcon" class="" />
          </b-button>
        </b-col>

        <!-- <b-col md="2">
          <b-form-group
            label="Subject"
            invalid-feedback="Subject is required."
            ref="subject"
          >
            <v-select
              ref="subject"
              v-model="myObj.subID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="allSubjects"
              :reduce="(val) => val.id"
              label="subject"
              :clearable="false"
              :disabled="dataLoading"
              placeholder="Select subject"
            />
          </b-form-group>
        </b-col> -->
      </b-row>
    </b-card>
    <!-- <b-row class="match-height" v-if="showSubjects">
      <div
        style="display: flex; justify-content: center; width: 100%"
        v-if="subLoading"
      >
        <b-spinner
          style="width: 4rem; height: 4rem"
          variant="primary"
          label="Spinning"
        ></b-spinner>
      </div>
      <b-col
        v-else-if="subjects.length > 0"
        xl="2"
        lg="2"
        md="4"
        v-for="(sub, ind) in subjects"
        :key="sub"
        class="pl-0"
      >
        <b-row
          style="width: 100%"
          class="mb-2 cursor-pointer"
          @click="show(sub.id)"
        >
          <b-col xl="3" lg="3" md="3" class="pr-0">
            <b-card
              class="justify-content-center"
              style="height: 100%"
              :variant="`${color[ind % color.length]}`"
              no-body
            >
              <b-card-title class="m-0 text-center" style="color: white">{{
                sub.subject.charAt(0)
              }}</b-card-title>
            </b-card>
          </b-col>
          <b-col
            xl="9"
            lg="9"
            md="9"
            class="pr-0"
            :class="selectedSub(sub.id) && 'change-bg'"
            style="border-radius: 0px 10px 10px 0px"
          >
            <h4 class="mt-50">{{ sub.subject }}</h4>
          </b-col>
        </b-row>
      </b-col>
      <div style="display: flex; justify-content: center; width: 100%" v-else>
        <h4>No Records Found</h4>
      </div>
    </b-row> -->
    <div v-if="showGrid">
      <b-card class="mt-2">
        <!-- <div
            class="d-flex align-items-center justify-content-between"
            style="width: 100%"
          >
            <b-card-title class="m-0">Result</b-card-title>
             <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              class="mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="Print()"
            >
              <feather-icon icon="PrinterIcon" class="" />
              Print
            </b-button> 
          </div> -->
        <b-row>
          <b-col md="12">
            <div class="d-flex flex-wrap justify-content-between mb-1">
              <h3 class="mb-0">
                {{ currentSub }}
                <!-- <span style="font-size: 1.25rem">({{ myObj.date }})</span> -->
              </h3>
              <div>
                <b-button
                  v-if="
                    settObj &&
                    settObj.valueBool &&
                    currentSub.toLowerCase() == 'attendance'
                  "
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="primary"
                  class="btn-icon mr-1"
                  @click="LoadAttMarks()"
                  :disabled="request || gridLoading || attLoading"
                  v-b-tooltip.hover.top
                  title="Attendance Marks"
                >
                  <b-spinner
                    v-if="attLoading"
                    small
                    label="Spinning"
                  ></b-spinner>
                  <feather-icon v-else icon="AwardIcon" />
                </b-button>
                <b-button
                  v-if="!newEntry"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="primary"
                  class="btn-icon"
                  @click="loadMissing()"
                  :disabled="request || gridLoading"
                  v-b-tooltip.hover.top
                  title="Sync Data"
                >
                  <!-- Load Missing Students -->
                  <b-spinner
                    v-if="gridLoading"
                    small
                    label="Spinning"
                  ></b-spinner>
                  <feather-icon v-else icon="RefreshCcwIcon" />
                </b-button>
              </div>
            </div>
          </b-col>
          <b-col
            xl="5"
            lg="5"
            md="12"
            sm="12"
            cols="12"
            class="d-flex align-items-center pr-0 mt-1"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-secondary"
              class="btn-icon mr-50"
              @click="Back()"
            >
              <feather-icon icon="ArrowLeftIcon" />
            </b-button>
            <b-form-input
              style=""
              v-model="searchQuery"
              class="d-inline-block mr-50"
              placeholder="Search..."
            />
          </b-col>
          <b-col xl="1" lg="1" md="2" sm="2" cols="12" class="pr-0">
            <b-form-group
              label="Total"
              invalid-feedback="Total is required."
              ref="total"
            >
              <b-form-input
                id="mc-first-name"
                placeholder="Enter total"
                ref="total"
                type="number"
                v-model="marksData.totalMarks"
                @keyup.enter="firstFocus()"
              />
            </b-form-group>
          </b-col>
          <b-col xl="2" lg="2" md="3" sm="3" cols="12" class="pr-0">
            <b-form-group
              label="Date"
              invalid-feedback="Date is required."
              ref="date"
              class=""
            >
              <flat-pickr
                :config="config"
                ref="date"
                v-model="marksData.date"
                class="form-control"
                placeholder="Select Date."
              />
            </b-form-group>
          </b-col>
          <b-col xl="2" lg="2" md="4" sm="4" cols="12" class="pr-0 mt-2">
            <b-button
              variant="success"
              class="mt-50"
              @click="enterGrade ? saveGrade() : Save()"
              :disabled="request || gridLoading"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
          <b-col xl="2" lg="2" md="3" sm="3" cols="12" class="">
            <b-form-group label="Sort By">
              <div class="d-flex">
                <v-select
                  v-model="sortBy"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="sortOptions"
                  :reduce="(val) => val.value"
                  label="text"
                  @input="sortMarks()"
                  :clearable="false"
                  placeholder="Select"
                />
                <!-- <b-form-checkbox v-model="enterGrade" switch /> -->
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-table
            class="mt-1"
            ref="selectableTable"
            :items="filters"
            :fields="
              newEntry || items[0].obj == null || enterGrade
                ? fields
                : editFields
            "
            show-empty
            :busy="gridLoading"
            responsive
            hover
          >
            <template #table-busy>
              <div
                class="d-flex justify-content-center mb-3"
                style="margin-top: 50px"
              >
                <b-spinner
                  style="width: 3rem; height: 3rem"
                  type="grow"
                  variant="primary"
                  label="Spinning"
                ></b-spinner>
              </div>
            </template>
            <template #cell(index)="data">
              <b-badge variant="light-primary">
                {{ items.indexOf(data.item) + 1 }}
              </b-badge>
            </template>
            <template #cell(grNo)="data">
              <b-badge style="margin-inline: 2px" variant="light-primary">
                {{ data.item.grNo }}
              </b-badge>
            </template>
            <template #cell(name)="data">
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    size="44"
                    :src="data.item.avatar"
                    variant="light-primary"
                  />
                </template>

                <b-link
                  class="font-weight-bold d-block text-nowrap"
                  style="margin-bottom: 4px"
                >
                  <b-badge variant="light-primary">
                    {{ data.item.name }}
                  </b-badge>
                </b-link>
                <b-link
                  class="font-weight-bold d-block text-nowrap"
                  style="margin-bottom: 4px"
                >
                  <b-badge variant="light-primary">
                    F/N: {{ data.item.father_name }}
                  </b-badge>
                </b-link>
                <small class="text-muted">
                  <b-badge variant="light-primary">
                    GR - {{ data.item.grNo }}
                  </b-badge>
                </small>
              </b-media>
              <!-- <div class="d-flex align-items-center">
                <b-avatar
                  size="40"
                  class="mr-50"
                  :src="data.item.avatar"
                  variant="light-primary"
                />
                <b-badge variant="light-primary">
                  {{ data.item.name }}
                </b-badge>
              </div> -->
            </template>
            <template #head(obtained_marks)="data">
              <div>
                <span>{{ enterGrade ? "grade" : data.label }}</span>
              </div>
            </template>
            <template #cell(obtained_marks)="data">
              <!-- <b-form-input
                style="width: 27%"
                class="marks-field"
                placeholder="Enter obtained marks here"
                ref="marks"
                type="number"
                :disabled="!marksObj[data.index].att"
                v-model="marksObj[data.index].obtained_marks"
                @keyup.enter="updateMarks(data.index, data.item)"
              /> -->
              <b-form-group
                label=""
                invalid-feedback="Invalid entry"
                :ref="'marks' + data.index"
              >
                <b-form-input
                  v-if="enterGrade"
                  :id="'mid' + data.index"
                  style="width: 100px"
                  class="marks-field"
                  placeholder="Enter grade here"
                  :disabled="['A', 'L'].includes(data.item.attendance)"
                  v-model="data.item.grade"
                  @keyup.enter="moveFocus(data.index, data.item)"
                />
                <b-form-input
                  v-else
                  :id="'mid' + data.index"
                  style="width: 80px"
                  class="marks-field"
                  placeholder="Enter obtained marks here"
                  :disabled="['A', 'L'].includes(data.item.attendance)"
                  v-model="data.item.obtained"
                  @input="checkValues(data.index)"
                  @keyup.enter="moveFocus(data.index, data.item)"
                />
              </b-form-group>
            </template>
            <template #cell(attendance)="data">
              <div class="d-flex align-items-center">
                <b-button
                  pill
                  size="sm"
                  style="margin-inline: 2px"
                  :variant="
                    data.item.attendance == 'P' ? 'success' : 'outline-success'
                  "
                  @click="changeAtt(data.index, 'P')"
                >
                  <span>P</span>
                </b-button>
                <b-button
                  pill
                  size="sm"
                  style="margin-inline: 2px"
                  :variant="
                    data.item.attendance == 'A' ? 'danger' : 'outline-danger'
                  "
                  @click="changeAtt(data.index, 'A')"
                >
                  <span>A</span>
                </b-button>
                <b-button
                  pill
                  size="sm"
                  style="margin-inline: 2px"
                  :variant="
                    data.item.attendance == 'L' ? 'info' : 'outline-info'
                  "
                  @click="changeAtt(data.index, 'L')"
                  v-b-tooltip.hover.top
                  title="Leave"
                >
                  <span>L</span>
                </b-button>
              </div>
            </template>

            <template #head(actions)="data">
              <div class="mr-5 text-right">
                <span>{{ data.label }}</span>
              </div>
            </template>

            <template #cell(grade)="data">
              <b-badge
                v-if="data.item.obj.meta_sub_grade"
                :variant="
                  data.item.obj.meta_sub_grade.toLowerCase() == 'fail' ||
                  data.item.obj.meta_sub_grade.toLowerCase() == 'failed' ||
                  data.item.obj.meta_sub_grade.toLowerCase() == 'f'
                    ? 'light-danger'
                    : 'light-primary'
                "
              >
                {{ data.item.obj.meta_sub_grade }}
              </b-badge>
            </template>
            <template #cell(percentage)="data">
              <b-badge variant="light-primary">
                {{ data.item.obj.meta_subj_percenatge }} <span>%</span>
              </b-badge>
            </template>
            <template #cell(rank)="data">
              <b-badge
                :variant="
                  data.item.obj.rank == '1st' ||
                  data.item.obj.rank == '2nd' ||
                  data.item.obj.rank == '3rd'
                    ? 'light-success'
                    : 'light-primary'
                "
              >
                {{ data.item.obj.rank }}
              </b-badge>
            </template>

            <template #cell(status)="data">
              <b-badge
                :variant="
                  data.item.obj.status == 'PASSED'
                    ? 'light-success'
                    : 'light-danger'
                "
              >
                {{ data.item.obj.status }}
              </b-badge>
            </template>

            <!-- <template #cell(actions)="data">
              <div class="text-right">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                  @click="ViewDetails(data.item)"
                >
                  <feather-icon icon="EyeIcon" class="" />
                </b-button>
  
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                  @click="Edit(data.item)"
                >
                  <feather-icon icon="EditIcon" class="" />
                </b-button>
  
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-secondary"
                  class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                  @click="Print()"
                >
                  <feather-icon icon="PrinterIcon" />
                </b-button>
              </div>
            </template> -->
          </b-table>
        </b-row>
      </b-card>
    </div>
    <b-card v-if="showRecords">
      <b-row>
        <b-col v-if="subLoading" class="text-center">
          <b-spinner
            style="width: 2.5rem; height: 2.5rem"
            variant="primary"
            label="Spinning"
          ></b-spinner>
        </b-col>
        <div v-else style="width: 100%" class="pl-1 d-flex flex-wrap">
          <b-button
            v-for="(sub, ind) in subjects"
            :key="sub"
            class="mr-50 mb-50"
            :variant="`outline-${color[ind % color.length]}`"
            @click="newEntryLoad(sub.id, sub.subject)"
          >
            {{ sub.subject }}
          </b-button>
          <b-button
            class="btn-icon mr-50 mb-50 ml-auto"
            variant="primary"
            @click="loadAllSubs()"
            v-b-tooltip.hover.top
            title="Load All Subjects"
          >
            <feather-icon icon="RefreshCcwIcon" />
          </b-button>
        </div>
        <!-- <div style="width: 5%; text-align: right" class="pr-50 cursor-pointer">
          <feather-icon icon="SettingsIcon" size="24" @click="openSettings()" />
        </div> -->

        <!--
        <b-col md="12">
          <b-button
            v-for="(sub, ind) in subjects"
            :key="sub"
            class="mr-50 mb-50"
             :variant="`outline-${color[ind % color.length]}`"
            @click="newEntryLoad(sub.id, sub.subject)"
          >
            {{ sub.subject }}
          </b-button>
          
        </b-col>
        -->
      </b-row>
      <b-row>
        <b-col v-if="gridSelected.length > 0" md="12" class="text-right my-50">
          <b-button
            class="mr-1"
            variant="success"
            @click="sendMsgMulti()"
            :disabled="sending"
          >
            Send ({{ gridSelected.length }})
          </b-button>
          <b-button
            class=""
            variant="outline-secondary"
            @click="clearSelected()"
            :disabled="sending"
          >
            Clear
          </b-button>
        </b-col>
        <b-table
          ref="recordTable"
          class="mt-1"
          :items="records"
          :fields="recordFields"
          show-empty
          :busy="loadingRec"
          responsive
          hover
          selectable
          select-mode="multi"
          @row-selected="onRowSelected"
        >
          <template #table-busy>
            <div
              class="d-flex justify-content-center mb-3"
              style="margin-top: 50px"
            >
              <b-spinner
                style="width: 3rem; height: 3rem"
                type="grow"
                variant="primary"
                label="Spinning"
              ></b-spinner>
            </div>
          </template>

          <template #head(actions)="data">
            <div class="text-center">
              <span>{{ data.label }}</span>
            </div>
          </template>

          <template #cell(date)="data">
            <b-badge variant="light-primary">
              {{
                new Date(data.item.date).toLocaleDateString("en-UK", {
                  year: "numeric",
                  day: "numeric",
                  month: "short",
                })
              }}
            </b-badge>
          </template>

          <template #cell(examType)="data">
            <b-badge variant="light-primary">
              {{ data.item.examType }}
            </b-badge>
          </template>

          <template #cell(actions)="data">
            <div class="d-flex justify-content-center">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                v-b-tooltip.hover.top
                title="Edit"
                class="btn-icon mr-50"
                @click="show(data.item)"
              >
                <feather-icon icon="EditIcon" class="" />
              </b-button>
              <b-button
                v-if="rights.edit"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                v-b-tooltip.hover.top
                title="Update"
                class="btn-icon mr-50"
                @click="openTerm(data.item)"
              >
                <feather-icon icon="Edit2Icon" class="" />
              </b-button>
              <b-button
                v-if="rights.delete"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                class="btn-icon mr-50"
                @click="Delete(data.item.marksGrid.id)"
                v-b-tooltip.hover.top
                title="Delete"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                v-b-tooltip.hover.top
                title="Send Message"
                class="btn-icon mr-50"
                :disabled="sending && currentID == data.item.marksGrid.id"
                @click="sendMsg(data.item.marksGrid.id)"
              >
                <b-spinner
                  v-if="sending && currentID == data.item.marksGrid.id"
                  small
                />
                <feather-icon v-else icon="MessageSquareIcon" class="" />
              </b-button>

              <b-button
                variant="outline-primary"
                v-b-tooltip.hover.top
                title="Export"
                class="btn-icon mr-50"
                :disabled="exporting && currentExp == data.item.marksGrid.id"
                @click="loadExport(data.item)"
              >
                <b-spinner
                  v-if="exporting && currentExp == data.item.marksGrid.id"
                  small
                />
                <feather-icon v-else icon="UploadIcon" class="" />
              </b-button>

              <!-- v-if="reportDomain == 'myskoolveritas'" -->
              <b-button
                variant="outline-primary"
                v-b-tooltip.hover.top
                title="PTM Report"
                class="btn-icon"
                @click="openPtm(data.item.marksGrid)"
              >
                <feather-icon icon="FileIcon" class="" />
              </b-button>
              <!-- <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                v-b-tooltip.hover.right
                title="Bonus"
                class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                @click="Bonus(data.item)"
              >
                <feather-icon icon="StarIcon" class="" />
              </b-button> -->
            </div>
          </template>
        </b-table>
      </b-row>
    </b-card>
    <b-card v-if="showDetils" class="mt-1">
      <b-row>
        <b-col>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-secondary"
            class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
            @click="Back()"
          >
            <feather-icon icon="ChevronLeftIcon" />
            Back
          </b-button>
        </b-col>
      </b-row>
      <b-card-title class="mt-1">Result</b-card-title>

      <chartjs-component-bar-chart
        :height="400"
        :data="barchartOptions.data"
        :options="barchartOptions.options"
        :plugins="plugins"
      />
    </b-card>
  </div>
</template>

<script>
import { required, minLength, between } from "vuelidate/lib/validators";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BInputGroup,
  BAvatar,
  BFormTextarea,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import store from "@/store";
import { avatarText } from "@core/utils/filter";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import ChartjsComponentBarChart from "@/views/charts-and-maps/charts/chartjs/charts-components/ChartjsComponentBarChart.vue";

import { mapActions, mapGetters } from "vuex";
import * as XLSX from "xlsx";

import { $themeColors } from "@themeConfig";

const chartColors = {
  primaryColorShade: "#836AF9",
  yellowColor: "#ffe800",
  successColorShade: "#28dac6",
  warningColorShade: "#ffe802",
  warningLightColor: "#FDAC34",
  infoColorShade: "#299AFF",
  greyColor: "#4F5D70",
  blueColor: "#2c9aff",
  blueLightColor: "#84D0FF",
  greyLightColor: "#EDF1F4",
  tooltipShadow: "rgba(0, 0, 0, 0.25)",
  lineChartPrimary: "#666ee8",
  lineChartDanger: "#ff4961",
  labelColor: "#6e6b7b",
  grid_line_color: "rgba(200, 200, 200, 0.2)",
};

export default {
  components: {
    //Multiselect,
    ChartjsComponentBarChart,
    BFormTextarea,
    BAvatar,
    BMediaAside,
    BMedia,
    BCard,
    BCardTitle,
    BRow,
    BBadge,
    BTable,
    BCol,
    BFormInput,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormCheckbox,
    BForm,
    BPagination,
    BSpinner,
    BDropdown,
    BDropdownItem,
    vSelect,
    flatPickr,
  },
  props: {},
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  mounted() {
    window.addEventListener("keydown", this.handleEvent);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleEvent);
  },
  async created() {
    if (!this.$store.state.rights[this.$route.name]) {
      this.$router.replace({
        name: "misc-not-authorized",
      });
    } else {
      this.rights = this.$store.state.rights[this.$route.name];

      // this.$store.commit("setSelection", []);
      if (this.$store.state.sortBy == "") {
        this.$store.commit("setSort", "name");
        this.sortBy = this.$store.state.sortBy;
        // console.log(this.sortBy, this.$store.state.sortBy);
      }
      this.collection = this.$store.state.marksSelection;

      if (!Array.isArray(this.collection)) {
        this.$store.commit("setSelection", []);
      }
      this.currentColl = this.collection.find(
        (el) => el.campusID == this.$store.state.userData.cId
      );
      // console.log(this.currentColl);
      if (this.currentColl) {
        // console.log(this.currentColl, this.$store.state.marksSelection);
        this.myObj = this.currentColl;
      }

      this.LoadData();
      this.LoadSession();
      this.LoadSettings();
      // console.log(this.myObj);
    }
  },
  computed: {
    ...mapGetters({ cmbDepartment: "cmbDepartment" }),
    ...mapGetters({ data: "data" }),

    computedfields() {
      if (this.newEntry) return this.fields;
      else return this.editFields;
    },
    filters: function () {
      return this.items.filter((pro) => {
        return (
          pro.name.toLowerCase().match(this.searchQuery.toLowerCase()) ||
          pro.grNo.toLowerCase().match(this.searchQuery.toLowerCase())
        );
      });
    },
  },
  data() {
    return {
      rights: {},
      reportDomain: this.$store.state.report.replace(/_/g, ""),
      collection: [],
      currentColl: null,
      clikced: 1,
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
        // mode: "range",
      },
      request: false,
      loadingRec: false,
      settLoading: false,
      colorDep: [],
      visibility2: false,
      color: ["primary", "danger", "success", "warning", "info", "secondary"],
      sidebarTitle: "Add Balance",
      sidebarButton: "Save",
      buttonIcon: "PlusIcon",
      dataLoading: false,

      visibility: false,
      logoloading: "",
      searchQuery: "",
      currentPage: 1,
      perPage: 30,
      totalRows: 0,

      errors: {
        status: false,
      },

      index: null,

      fields: [
        // { label: "S.No", key: "index" },
        // { label: "gr no", key: "grNo" },
        { label: "name", key: "name" },
        // { label: "Father name", key: "father_name" },
        // { label: "Max. marks", key: "max_marks" },
        { label: "attendance", key: "attendance" },
        { label: "Obt. marks", key: "obtained_marks" },
        // { label: "percentage", key: "percentage" },
        // { label: "grade", key: "grade" },
        // { key: "actions", label: "actions" },
      ],
      editFields: [
        { label: "S.No", key: "index" },
        // { label: "gr no", key: "grNo" },
        { label: "name", key: "name" },
        { label: "attendance", key: "attendance" },
        { label: "Obt. marks", key: "obtained_marks" },
        { label: "grade", key: "grade" },
        { label: "percentage", key: "percentage" },
        { label: "rank", key: "rank" },
        { label: "status", key: "status" },
        // { key: "actions", label: "actions" },
      ],
      recordFields: [
        { label: "subject", key: "subject" },
        { label: "date", key: "date" },
        { label: "Exam", key: "examType" },
        { key: "actions", label: "actions" },
      ],
      items: [],
      newData: [],
      sortOptions: [
        { text: "GR#", value: "gr" },
        { text: "Name", value: "name" },
      ],
      sortBy: this.$store.state.sortBy,
      accountOptions: [
        // {
        //   id: 1,
        //   code: 999,
        // },
      ],
      sections: [],
      secText: "Section",
      classes: [],
      classesOptions: [],
      currentSections: [],
      subjects: [],
      classText: "Class",
      sessions: [],
      sessionText: "Session",
      examType: [],
      examText: "Exam Type",
      selected: null,
      rangeDate: null,
      bonusTypes: [
        { text: "Average", value: "average" },
        { text: "Grading", value: "grading" },
      ],
      bonusObj: {
        id: 0,
        examID: 0,
        type: "",
      },
      recordObj: {},
      myObj: {
        clsID: 0,
        secID: 0,
        examID: 0,
        sessionID: 0,
        // date: "",
        subID: 0,
        campusID: this.$store.state.userData.cId,
      },
      marksData: {
        totalMarks: null,
      },
      gridLoading: false,
      marksObj: [],
      maxObj: {
        number: 10,
      },
      depObj: {
        id: 0,
        name: "",
        campusID: this.$store.state.userData.cId,
      },
      detailObj: {},
      timeout: 10000,
      showGrid: false,
      sheetLoading: false,
      currentSub: "",
      currentExam: "",
      currentClass: "",
      currentSession: "",
      sectionName: "",
      showRecords: false,
      records: [],
      showDetils: false,

      subLoading: false,
      showSubjects: false,
      plugins: [
        {
          beforeInit(chart) {
            chart.legend.afterFit = function () {
              this.height += 20;
            };
          },
        },
      ],
      barchartOptions: {
        data: {
          labels: [
            "Computer",
            "Chemistry",
            "Mathematics",
            "Biology",
            "Islamic History",
            "English",
            "Urdu",
          ],
          datasets: [
            {
              label: "Marks",
              data: ["66", "75", "70", "89", "67", "52", "91"],
              backgroundColor: $themeColors.success,
              borderColor: "transparent",
            },
            // {
            //   label: "Girls",
            //   data: ["18", "44", "30", "24", "75", "50", "52", "71"],
            //   backgroundColor: "#00cfe8",
            //   borderColor: "transparent",
            // },
          ],
        },
        options: {
          elements: {
            rectangle: {
              borderWidth: 5,
              borderSkipped: "bottom",
              barThickness: 40,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            position: "top",
            align: "end",
            display: true,
            labels: {
              usePointStyle: true,
              boxWidth: 12,
            },
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  display: true,
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
                scaleLabel: {
                  display: false,
                },
                ticks: {
                  fontColor: chartColors.labelColor,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
                ticks: {
                  stepSize: 20,
                  min: 0,
                  max: 100,
                  fontColor: chartColors.labelColor,
                },
              },
            ],
          },
        },
      },
      visibility3: false,
      msgObj: {
        clsIDs: [],
        secIDs: [],
        subIDs: [],
        examID: 0,
        sessionID: 0,
        date: "",
      },
      msgSecOptions: [{ section: "All Sections", id: 0 }],
      msgClass: [0],
      msgSection: [0],
      msgClassOptions: [],
      sending: false,
      visibility4: false,
      changing: false,
      termObj: {},
      tempOptions: [],
      temp: 0,
      tempMessage: null,
      currentID: null,
      currentExp: null,
      exporting: false,
      sessionObj: {
        id: 0,
        session: "",
        campusID: this.$store.state.userData.cId,
      },
      sessionbar: false,
      savingacc: false,
      sessFields: [
        { label: "Name", key: "session" },
        { key: "actions", label: "actions" },
      ],
      enterGrade: false,
      gridSelected: [],
      settObj: null,
      attLoading: false,
    };
  },
  validations: {
    depObj: {
      name: {
        required,
        minLength: minLength(4),
      },
    },
    age: {
      between: between(20, 30),
    },
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),
    handleEvent(event) {
      if (event.ctrlKey && event.keyCode === 83) {
        // console.log(event);
        event.preventDefault();
        if (this.clikced == 1 && this.showGrid) {
          this.clikced = 0;
          if (this.enterGrade) this.saveGrade();
          else this.Save();
        }
      }
    },
    async LoadSettings() {
      let res = await this.post({
        url:
          this.$store.state.domain +
          "Settings/getSettingsAll?db=" +
          this.$store.state.userData.db,
        body: ["attendance_marks"],
        context: this,
        token: this.$store.state.userData.token,
        subjects: true,
      });

      this.settObj = res.find((el) => el.key == "attendance_marks");
    },

    async LoadSession() {
      var obj2 = {
        url:
          this.$store.state.domain +
          "Sessions?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.sessions = await this.get(obj2);
      // console.log("Session", this.sessions);
    },
    openSession() {
      this.accIcon = "PlusIcon";
      this.sessionbar = true;

      this.sessionObj = {
        id: 0,
        session: "",
        campusID: this.$store.state.userData.cId,
      };
      var elem = this.$refs["sess_name"];
      elem.state = undefined;
    },
    SessName() {
      var elem = this.$refs["sess_name"];
      if (this.sessionObj.session.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async saveSession() {
      if (this.SessName() == true) {
        this.savingacc = true;
        if (this.sessionObj.id == 0) {
          var status = await this.post({
            url:
              this.$store.state.domain +
              "Sessions?db=" +
              this.$store.state.userData.db,
            body: this.sessionObj,
            message: "Session added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
        } else {
          var status = await this.put({
            url:
              this.$store.state.domain +
              "Sessions/" +
              this.sessionObj.id +
              "?db=" +
              this.$store.state.userData.db,
            message: "Session updated successfully.",
            context: this,
            body: this.sessionObj,
            token: this.$store.state.userData.token,
          });
        }
        this.savingacc = false;
        if (status) {
          this.LoadSession();
          var elem = this.$refs["sess_name"];
          elem.state = undefined;
          this.sessionObj = {
            id: 0,
            session: "",
            campusID: this.$store.state.userData.cId,
          };
        }
      }
    },
    async editSess(item) {
      this.sessionObj = { ...item };
      this.accIcon = "Edit2Icon";
      var elem = this.$refs["sess_name"];
      elem.state = undefined;
      // console.log(this.sessionObj);
    },

    async deleteSess(item) {
      // console.log(acc);
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "Sessions/" +
            item.id +
            "?db=" +
            this.$store.state.userData.db,
          message: "Session removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadSession();
      }
    },
    async setCurrentSess(id) {
      var status = await this.post({
        url:
          this.$store.state.domain +
          "Sessions/SetCurrentSession?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&sessionID=" +
          id,
        body: this.sessionObj,
        message: "Session marked as current",
        context: this,
        token: this.$store.state.userData.token,
      });

      if (status) {
        this.LoadSession();
      }
    },
    sortMarks() {
      this.$store.commit("setSort", this.sortBy);

      this.items = this.items.sort((s1, s2) => {
        let fa, fb;
        if (this.sortBy == "gr") {
          fa = parseInt(s1.grNo);
          fb = parseInt(s2.grNo);
        } else {
          fa = s1.name.toLowerCase();
          fb = s2.name.toLowerCase();
        }

        // console.log(fa, fb);

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
    },
    async loadExport(item) {
      if (
        this.myObj.clsID !== 0 &&
        this.myObj.secID !== 0 &&
        this.myObj.sessionID !== 0 &&
        this.myObj.examID !== 0
      ) {
        this.exporting = true;
        this.currentExp = item.marksGrid.id;
        this.currentSub = item.subject;
        this.currentExam = item.examType;
        this.currentSession = this.sessions.find(
          (el) => el.id == this.myObj.sessionID
        ).session;

        let temp = this.classes.find((el) => el.cls.id == this.myObj.clsID);
        this.currentClass = temp.cls.name;
        this.sectionName = temp.sections.find(
          (el) => el.id == this.myObj.secID
        ).section;

        this.marksData = await this.get({
          url:
            this.$store.state.domain +
            "Marks/LoadMarksData?db=" +
            this.$store.state.userData.db +
            "&campusID=" +
            this.$store.state.userData.cId +
            "&clsID=" +
            this.myObj.clsID +
            "&sec=" +
            this.myObj.secID +
            "&subID=" +
            item.marksGrid.subjectID +
            "&examTypeID=" +
            this.myObj.examID +
            "&sessionID=" +
            this.myObj.sessionID +
            "&isGraded=false&date=" +
            item.date +
            "&marksGridID=" +
            item.marksGrid.id,
          context: this,
          token: this.$store.state.userData.token,
        });
        // console.log(this.marksData);
        this.items = this.marksData.data;
        this.newData = this.marksData.data;
        this.marksData.marksGrid = item.marksGrid;

        this.exportData();
      } else {
        this.$bvToast.toast("Please select all the requird fields", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      }
    },
    exportData() {
      this.exporting = true;

      const newData = this.marksData.data.map((item) => ({
        date: this.marksData.date,
        grNo: item.grNo,
        name: item.name,
        attendance: item.obj.attendance,
        obtained: item.obj.obtained,
        total: item.obj.total,
        grade: item.obj.meta_sub_grade,
        percenatge: item.obj.meta_subj_percenatge,
        rank: item.obj.rank,
        status: item.obj.status,
        subject: this.currentSub,
        class: this.currentClass + " - " + this.sectionName,
        exam: this.currentExam + " - " + this.currentSession,
      }));

      const worksheet = XLSX.utils.json_to_sheet(newData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
      XLSX.writeFile(workbook, "marks.xlsx");

      this.exporting = false;
    },
    openPtm(item) {
      // console.log(item);
      let url = `https://${this.reportDomain}.myskool.app/Exams/ShowPTMReport?dbb=${this.$store.state.userData.db}&cID=${this.$store.state.userData.cId}&cls=${item.cID}&sec=${item.secID}&examID=${item.examID}&sessionID=${item.sessionID}&subID=${item.subjectID}`;
      window.open(url, "_blank");
    },

    async sendMsg(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "Do you want to send the message?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, send it!",
      });
      if (result.isConfirmed) {
        this.sending = true;
        this.currentID = id;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Marks/SendMessage?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&gridID=" +
            id +
            "&userID=" +
            this.$store.state.userData.userID,
          body: null,
          message: "Message sent successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.sending = false;
      }
    },
    async sendMsgMulti() {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "Do you want to send the message?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, send it!",
      });
      if (result.isConfirmed) {
        this.sending = true;
        let ids = this.gridSelected.reduce((acc, el) => {
          acc.push(el.marksGrid.id);
          return acc;
        }, []);
        // console.log(ids);
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Marks/SendMessageMulti?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&gridID=" +
            ids +
            "&userID=" +
            this.$store.state.userData.userID,
          body: null,
          message: "Message sent successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.sending = false;
        if (status) this.clearSelected();
      }
    },
    openTerm(item) {
      this.termObj = { ...item.marksGrid };
      this.visibility4 = true;
    },
    onRowSelected(row) {
      this.gridSelected = row;
      // console.log(this.gridSelected);
    },
    clearSelected() {
      this.$refs.recordTable.clearSelected();
    },
    async change() {
      this.changing = true;
      var status = await this.put({
        url:
          this.$store.state.domain +
          "MarksGrid/" +
          this.termObj.id +
          "?db=" +
          this.$store.state.userData.db,
        body: this.termObj,
        message: "Record updated successfully.",
        context: this,
        token: this.$store.state.userData.token,
      });
      this.changing = false;
      if (status) {
        this.Back();
        this.visibility4 = false;
      }
    },
    FillMsgSections() {
      // console.log(this.msgClass);
      if (this.msgClass[0] == 0 && this.msgClass.length !== 1) {
        this.msgClass = this.msgClass.filter((e) => e !== 0);
        // console.log(this.msgClass);
        this.classes.forEach((elem) => {
          if (this.msgClass.includes(elem.cls.id)) {
            if (elem.sections.length != 0) {
              elem.sections.forEach((el) => this.msgSecOptions.push(el));
            } else {
              // console.log("null");
              this.msgSecOptions = [{ section: "All Sections", id: 0 }];
            }
          }
        });
      } else if (
        this.msgClass[this.msgClass.length - 1] !== 0 &&
        this.msgClass.length == 1
      ) {
        this.classes.forEach((elem) => {
          if (this.msgClass.includes(elem.cls.id)) {
            if (elem.sections.length != 0) {
              elem.sections.forEach((el) => this.msgSecOptions.push(el));
            } else {
              // console.log("null");
              this.msgSecOptions = [{ section: "All Sections", id: 0 }];
            }
          }
        });
      } else if (this.msgClass[this.msgClass.length - 1] == 0) {
        this.msgClass = [0];
        // console.log(this.msgClass);
        this.msgSecOptions = [{ section: "All Sections", id: 0 }];
        this.msgSection = [0];
      } else {
        this.msgSecOptions = [{ section: "All Sections", id: 0 }];
        this.msgSection = [0];
      }
      // this.CheckClass();
    },
    checkMsgSections() {
      // console.log(this.msgSection);
      if (this.msgSection[0] == 0 && this.msgSection.length !== 1) {
        this.msgSection = this.msgSection.filter((e) => e !== 0);
        // console.log(this.msgSection);
      } else if (this.msgSection[this.msgSection.length - 1] == 0) {
        this.msgSection = [0];
        // console.log(this.msgSection);
      }
    },
    openMessage() {
      this.msgClassOptions = [];
      this.classes.forEach((el) => this.msgClassOptions.push(el.cls));
      this.msgClassOptions.unshift({ id: 0, name: "All Classes" });
      this.visibility3 = true;
    },
    async sendMessage() {
      if (
        this.msgClass.length == 0 ||
        this.msgSection.length == 0 ||
        this.msgObj.date == "" ||
        this.msgObj.sessionID == 0 ||
        this.msgObj.examID == 0
      ) {
        return this.$bvToast.toast("Please select all the required fields", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        if (this.msgClass[0] !== 0) {
          this.msgObj.clsIDs = this.msgClass;
        } else {
          this.msgObj.clsIDs = [];
        }
        if (this.msgSection[0] !== 0) {
          this.msgObj.secIDs = this.msgSection;
        } else {
          this.msgObj.secIDs = [];
        }
        this.sending = true;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Marks/SendMessage?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&clsID=" +
            this.msgObj.clsIDs +
            "&sec=" +
            this.msgObj.secIDs +
            "&subjectID=" +
            this.msgObj.subIDs +
            "&examID=" +
            this.msgObj.examID +
            "&sessionID=" +
            this.msgObj.sessionID +
            "&date=" +
            this.msgObj.date,
          body: this.msgObj,
          message: "Message sent successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.sending = false;
        if (status) this.visibility3 = false;
      }
    },
    changeAtt(ind, val) {
      this.filters[ind].attendance = val;
      if (["A", "L"].includes(val)) {
        this.filters[ind].obtained = 0;
        this.filters[ind].grade = null;
      }
    },
    checkValues(ind) {
      var elem = this.$refs["marks" + ind];
      let re = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
      // console.log(
      //   re.test(this.filters[ind].obtained),
      //   this.filters[ind].obtained == "A"
      // );
      if (re.test(this.filters[ind].obtained)) {
        if (
          parseFloat(this.filters[ind].obtained) >
          parseFloat(this.marksData.totalMarks)
        ) {
          return (elem.state = false);
        } else {
          return (elem.state = undefined);
        }
      } else if (this.filters[ind].obtained == "A") {
        return (elem.state = undefined);
      } else {
        return (elem.state = false);
      }

      // if (
      //   this.filters[ind].obtained < 0 ||
      //   parseInt(this.filters[ind].obtained) >
      //     parseInt(this.marksData.totalMarks)
      // ) {
      //   return (elem.state = false);
      // } else {
      //   return (elem.state = undefined);
      // }
    },
    async moveFocus(ind, item) {
      // console.log(item); // item for api body or mark obj id
      let all = document.querySelectorAll(".marks-field");
      // console.log(all);

      if (ind + 1 != all.length) {
        all[ind + 1].focus();
        all[ind + 1].select();
      }
      // if (this.marksObj[ind].obtained_marks) {
      //   // api here if success then below code will move auto focus on next

      //   // console.log(ind);

      // }
    },
    async Save() {
      if (this.marksData.totalMarks <= 0) {
        this.$bvToast.toast("Please enter correct total marks", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
        this.clikced = 1;
      } else if (this.marksData.date == "" || this.marksData.date == null) {
        this.$bvToast.toast("Please enter the date", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
        this.clikced = 1;
      } else {
        this.request = true;
        this.marksData.isGraded = false;
        this.marksData.totalMarks = parseInt(this.marksData.totalMarks);
        let check = true;
        let re = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
        this.items.forEach((el, ind) => {
          if (el.obtained == "A") {
            this.newData[ind].obtained = 0;
            this.newData[ind].attendance = "A";
          } else if (re.test(el.obtained)) {
            el.obtained = parseFloat(el.obtained);

            if (el.obtained > this.marksData.totalMarks) {
              check = false;
            } else {
              this.newData[ind].obtained = el.obtained;
              this.newData[ind].attendance = el.attendance;
            }
          } else {
            check = false;
          }

          // el.obtained = parseFloat(el.obtained);
          // if (isNaN(el.obtained)) el.obtained = 0;
          // if (el.obtained < 0 || el.obtained > this.marksData.totalMarks) {
          //   check = false;
          // }
        });
        if (check) {
          this.marksData.data = this.newData;
          // console.log(check);
          // console.log(this.marksData.data);
          var status = await this.post({
            url:
              this.$store.state.domain +
              "Marks/SaveMarks?db=" +
              this.$store.state.userData.db,
            body: this.marksData,
            message: "Marks updated successfully.",
            context: this,
            savingMarks: true,
            token: this.$store.state.userData.token,
          });
          if (status) {
            // console.log(status);
            this.showAfterSave(status);
          }
          this.request = false;
        } else {
          this.request = false;
          this.clikced = 1;
          this.$bvToast.toast("Please enter correct marks", {
            title: "Error!",
            variant: "danger",
            toaster: "b-toaster-top-center",
          });
        }
      }
    },
    async saveGrade() {
      if (this.marksData.date == "" || this.marksData.date == null) {
        this.$bvToast.toast("Please enter the date", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
        this.clikced = 1;
      } else {
        this.request = true;
        this.marksData.isGraded = true;
        this.marksData.totalMarks = 0;
        let check = true;

        this.items.forEach((el, ind) => {
          el.obtained = 0;
          this.newData[ind].obtained = 0;
          if (
            el.attendance == "P" &&
            (el.grade == null || el.grade.trim() == "")
          ) {
            check = false;
          }
        });
        if (check) {
          this.marksData.data = this.newData;
          // console.log(this.marksData);

          var status = await this.post({
            url:
              this.$store.state.domain +
              "Marks/SaveMarks?db=" +
              this.$store.state.userData.db,
            body: this.marksData,
            message: "Marks updated successfully.",
            context: this,
            savingMarks: true,
            token: this.$store.state.userData.token,
          });
          if (status) {
            console.log(status);
            this.showAfterSave(status);
          }
          this.request = false;
        } else {
          this.request = false;
          this.clikced = 1;
          this.$bvToast.toast("Please enter grade correctly", {
            title: "Error!",
            variant: "danger",
            toaster: "b-toaster-top-center",
          });
        }
      }
    },
    async Bonus(item) {
      //   var obj = {
      //     url:
      //       this.$store.state.domain +
      //       "examtypes/GetSelected?id=" +
      //       id +
      //       "&db=" +
      //       this.$store.state.userData.db,
      //     token: this.$store.state.userData.token,
      //   };
      //   this.myObj = await this.get(obj);
      //   console.log("editObj:", this.myObj);
      this.bonusObj = {
        id: 0,
        examID: 0,
        type: "",
      };
      this.visibility = true;
      this.sidebarTitle = "Add Bonus";
      this.sidebarButton = "Save";
      var elem = this.$refs["bon_exam"];
      elem.state = undefined;
      var elem = this.$refs["bon_type"];
      elem.state = undefined;
    },

    AddOpen() {
      this.myObj = {
        id: 0,
        account: 0,
        balance: 0,
        campusID: this.$store.state.userData.cId,
      };
      this.visibility = true;
      this.sidebarTitle = "Add balance";
      this.sidebarButton = "Save";
      var elem = this.$refs["account"];
      elem.state = undefined;
      var elem = this.$refs["balance"];
      elem.state = undefined;
    },

    CheckMarks() {
      var elem = this.$refs["marks"];
      var re = /^[0-9]+$/;
      if (re.test(this.marksObj.obtained_marks)) {
        this.marksObj.obtained_marks = parseInt(this.marksObj.obtained_marks);
        return (elem.state = true);
      } else {
        return (elem.state = false);
      }
    },

    hideSideBar() {
      this.visibility = false;
    },
    FillSections(text) {
      // console.log(this.myObj.clsID);

      this.classes.forEach((elem) => {
        if (elem.cls.id === this.myObj.clsID) {
          if (elem.sections.length != 0) {
            this.currentSections = elem.sections;
          } else {
            // console.log("null");
            this.currentSections = [];
          }
        }
      });
      if (text != "filled") this.myObj.secID = 0;
      // console.log("Thisis", this.currentSections);
    },
    ViewDetails(item) {
      this.showGrid = false;
      this.showDetils = true;
    },

    async Back() {
      // this.showDetils = false;
      this.showGrid = false;
      this.showRecords = true;
      this.loadingRec = true;
      this.gridSelected = [];

      this.records = await this.get({
        url:
          this.$store.state.domain +
          "Marks/LoadSubjectsGrid?db=" +
          this.$store.state.userData.db +
          "&campusID=" +
          this.$store.state.userData.cId +
          "&clsID=" +
          this.myObj.clsID +
          "&sec=" +
          this.myObj.secID +
          "&examTypeID=" +
          this.myObj.examID +
          "&sessionID=" +
          this.myObj.sessionID,
        context: this,
        token: this.$store.state.userData.token,
      });
      // console.log(this.records);

      this.loadingRec = false;
    },
    async LoadData() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "myclasses/LoadClasses?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId +
          "&dID=0",
        token: this.$store.state.userData.token,
      };
      this.classes = await this.get(obj);
      this.classesOptions = [];
      this.classes.forEach((el) => this.classesOptions.push(el.cls));

      this.FillSections("filled");
      // console.log("item", this.classes);

      var obj3 = {
        url:
          this.$store.state.domain +
          "ExamTypes?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.examType = await this.get(obj3);
      // console.log("exam type", this.examType);
      this.dataLoading = false;

      var obj = {
        url:
          this.$store.state.domain +
          "Subjects?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.allSubjects = await this.get(obj);

      this.LoadSubjects();
      this.loadRecords();

      // console.log("subject", this.allSubjects);
    },
    async loadAllSubs() {
      this.subLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "Subjects?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.allSubjects = await this.get(obj);
      this.subjects = this.allSubjects;
      this.subLoading = false;
    },
    async LoadSubjects(text) {
      if (this.myObj.clsID !== 0 && this.myObj.secID !== 0) {
        if (text == "load") this.loadRecords();
        this.showSubjects = true;
        this.subLoading = true;
        this.subjects = await this.get({
          url:
            this.$store.state.domain +
            "Marks/LoadClassSubjects?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&clsID=" +
            this.myObj.clsID +
            "&sec=" +
            this.myObj.secID,
          context: this,
          token: this.$store.state.userData.token,
        });
        // console.log(this.subjects);
        if (this.subjects.length == 0) this.subjects = this.allSubjects;
        this.subLoading = false;
      }
    },
    async dataSheet(text) {
      // console.log(text);
      if (
        this.myObj.clsID !== 0 &&
        this.myObj.secID !== 0 &&
        this.myObj.sessionID !== 0 &&
        this.myObj.examID !== 0
      ) {
        if (text == "BlankDatasheet") {
          var url = `https://${this.reportDomain}.myskool.app/Exams/${text}?dbb=${this.$store.state.userData.db}&cID=${this.$store.state.userData.cId}&cls=${this.myObj.clsID}&sec=${this.myObj.secID}&examID=${this.myObj.examID}&sessionID=${this.myObj.sessionID}&sID=&date=`;
          window.open(url, "_blank");
        } else if (text == "summary") {
          this.compileResult(text);
        } else if (
          text == "ptm" &&
          ["myskoolMnMschool", "myskoolMnMcoaching"].includes(this.reportDomain)
        ) {
          let url = `https://${this.reportDomain}.myskool.app/Exams/ShowPTMReport?dbb=${this.$store.state.userData.db}&cID=${this.$store.state.userData.cId}&cls=${this.myObj.clsID}&sec=${this.myObj.secID}&examID=${this.myObj.examID}&sessionID=${this.myObj.sessionID}&subID=&type=summary`;
          window.open(url, "_blank");
        } else {
          this.sheetLoading = true;
          let obj = {
            campusID: this.myObj.campusID,
            clsID: this.myObj.clsID,
            sec: this.myObj.secID,
            examTypeID: [this.myObj.examID],
            sessionID: this.myObj.sessionID,
            dtFrom: null,
            dtTo: null,
          };
          let res = await this.post({
            url:
              this.$store.state.domain +
              "Marks/CreateResult?db=" +
              this.$store.state.userData.db,
            body: obj,
            subjects: true,
            context: this,
            token: this.$store.state.userData.token,
          });
          this.sheetLoading = false;
          if (res.length > 0) {
            let url = "";
            if (text == "ptm") {
              url = `https://${this.reportDomain}.myskool.app/Exams/ShowPTMReport?dbb=${this.$store.state.userData.db}&cID=${this.$store.state.userData.cId}&cls=${this.myObj.clsID}&sec=${this.myObj.secID}&examID=${this.myObj.examID}&sessionID=${this.myObj.sessionID}&subID=&type=summary`;
            } else {
              url = `https://${this.reportDomain}.myskool.app/Exams/${text}?dbb=${this.$store.state.userData.db}&cID=${this.$store.state.userData.cId}&cls=${this.myObj.clsID}&sec=${this.myObj.secID}&examID=${this.myObj.examID}&sessionID=${this.myObj.sessionID}&sID=&date=`;
            }
            window.open(url, "_blank");
          } else {
            this.$bvToast.toast("No records found.", {
              title: "Error!",
              variant: "danger",
              toaster: "b-toaster-top-center",
            });
          }
        }
      } else {
        this.$bvToast.toast("Please select all the requird fields", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      }
    },
    async compileResult(text) {
      this.sheetLoading = true;
      let count = [];
      let show = true;

      for (const data of this.currentSections) {
        // console.log(data);
        let obj = {
          campusID: this.myObj.campusID,
          clsID: this.myObj.clsID,
          sec: data.id,
          examTypeID: [this.myObj.examID],
          sessionID: this.myObj.sessionID,
          dtFrom: null,
          dtTo: null,
        };
        let res = await this.post({
          url:
            this.$store.state.domain +
            "Marks/CreateResult?db=" +
            this.$store.state.userData.db,
          body: obj,
          subjects: true,
          context: this,
          token: this.$store.state.userData.token,
        });
        if (Array.isArray(res)) {
          count.push(res.length);
        } else {
          show = false;
          break;
        }
      }
      // console.log("for end");
      // console.log("count", count);
      if (show) {
        if (count.every((el) => el == 0)) {
          this.$bvToast.toast("No records found.", {
            title: "Error!",
            variant: "danger",
            toaster: "b-toaster-top-center",
          });
        } else {
          var url = `https://${this.reportDomain}.myskool.app/Exams/${text}?dbb=${this.$store.state.userData.db}&cID=${this.$store.state.userData.cId}&cls=${this.myObj.clsID}&sec=${this.myObj.secID}&examID=${this.myObj.examID}&sessionID=${this.myObj.sessionID}&sID=&date=`;
          window.open(url, "_blank");
        }
      }
      this.sheetLoading = false;
    },
    async loadRecords() {
      // console.log(this.myObj);

      this.myObj.campusID = this.$store.state.userData.cId;
      if (this.collection.length == 0) {
        this.collection.push(this.myObj);
      } else {
        this.currentColl = this.collection.find(
          (el) => el.campusID == this.$store.state.userData.cId
        );
        let ind = this.collection.indexOf(this.currentColl);
        // console.log(ind);
        if (ind == -1) {
          this.collection.push(this.myObj);
        } else {
          this.collection[ind] = this.myObj;
        }
      }
      this.$store.commit("setSelection", this.collection);

      if (
        this.myObj.clsID !== 0 &&
        this.myObj.secID !== 0 &&
        this.myObj.sessionID !== 0 &&
        this.myObj.examID !== 0
      ) {
        this.showGrid = false;
        this.showRecords = true;
        this.loadingRec = true;
        setTimeout(() => {
          this.clearSelected();
        }, 300);

        this.records = await this.get({
          url:
            this.$store.state.domain +
            "Marks/LoadSubjectsGrid?db=" +
            this.$store.state.userData.db +
            "&campusID=" +
            this.$store.state.userData.cId +
            "&clsID=" +
            this.myObj.clsID +
            "&sec=" +
            this.myObj.secID +
            "&examTypeID=" +
            this.myObj.examID +
            "&sessionID=" +
            this.myObj.sessionID,
          context: this,
          token: this.$store.state.userData.token,
        });
        // console.log(this.records);

        this.loadingRec = false;
      }
    },
    async newEntryLoad(id, name) {
      if (
        this.myObj.clsID !== 0 &&
        this.myObj.secID !== 0 &&
        this.myObj.sessionID !== 0 &&
        this.myObj.examID !== 0
      ) {
        this.showRecords = false;
        this.showGrid = true;
        this.newEntry = true;
        this.gridLoading = true;
        this.myObj.subID = id;
        this.currentSub = name;
        this.marksData = await this.get({
          url:
            this.$store.state.domain +
            "Marks/LoadMarksData?db=" +
            this.$store.state.userData.db +
            "&campusID=" +
            this.$store.state.userData.cId +
            "&clsID=" +
            this.myObj.clsID +
            "&sec=" +
            this.myObj.secID +
            "&subID=" +
            id +
            "&examTypeID=" +
            this.myObj.examID +
            "&sessionID=" +
            this.myObj.sessionID +
            "&isGraded=false&date=&marksGridID=",
          context: this,
          token: this.$store.state.userData.token,
        });
        // console.log(this.marksData);
        this.items = this.marksData.data;
        this.newData = this.marksData.data;

        if (this.marksData.date == null) this.marksData.date = new Date();

        this.gridLoading = false;
        setTimeout(() => {
          this.firstFocus();
        }, 500);
      } else {
        this.$bvToast.toast("Please select all the requird fields", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      }
    },

    async showAfterSave(item) {
      // console.log(item);
      this.gridLoading = true;
      this.newEntry = false;

      this.currentSub = this.allSubjects.find(
        (el) => el.id == item.subID
      ).subject;
      this.currentExam = this.examType.find(
        (el) => el.id == item.examTypeID
      ).examType;
      this.currentSession = this.sessions.find(
        (el) => el.id == item.sessionID
      ).session;

      let temp = this.classes.find((el) => el.cls.id == item.clsID);
      this.currentClass = temp.cls.name;
      this.sectionName = temp.sections.find((el) => el.id == item.sec).section;

      this.marksData = await this.get({
        url:
          this.$store.state.domain +
          "Marks/LoadMarksData?db=" +
          this.$store.state.userData.db +
          "&campusID=" +
          this.$store.state.userData.cId +
          "&clsID=" +
          item.clsID +
          "&sec=" +
          item.sec +
          "&subID=" +
          item.marksGrid.subjectID +
          "&examTypeID=" +
          item.examTypeID +
          "&sessionID=" +
          this.myObj.sessionID +
          "&isGraded=false&date=" +
          item.date +
          "&marksGridID=" +
          item.marksGrid.id,
        context: this,
        token: this.$store.state.userData.token,
      });
      // console.log(this.marksData);
      this.items = this.marksData.data;
      if (this.items[0].obj.isGraded == true) this.enterGrade = true;
      else this.enterGrade = false;

      this.newData = this.marksData.data;
      this.marksData.marksGrid = item.marksGrid;
      // console.log(this.items);
      this.gridLoading = false;
      this.sortMarks();
      this.clikced = 1;
      setTimeout(() => {
        this.firstFocus();
      }, 500);
    },

    async show(item) {
      // console.log(this.myObj);
      // this.showGrid = true;
      if (
        this.myObj.clsID !== 0 &&
        this.myObj.secID !== 0 &&
        // this.marksData.date !== "" &&
        this.myObj.sessionID !== 0 &&
        this.myObj.examID !== 0
      ) {
        this.recordObj = item;

        this.showRecords = false;
        this.showGrid = true;
        this.gridLoading = true;
        this.newEntry = false;
        this.myObj.subID = item.marksGrid.subjectID;
        this.currentSub = item.subject;
        this.currentExam = item.examType;
        this.currentSession = this.sessions.find(
          (el) => el.id == this.myObj.sessionID
        ).session;

        let temp = this.classes.find((el) => el.cls.id == this.myObj.clsID);
        this.currentClass = temp.cls.name;
        this.sectionName = temp.sections.find(
          (el) => el.id == this.myObj.secID
        ).section;

        this.marksData = await this.get({
          url:
            this.$store.state.domain +
            "Marks/LoadMarksData?db=" +
            this.$store.state.userData.db +
            "&campusID=" +
            this.$store.state.userData.cId +
            "&clsID=" +
            this.myObj.clsID +
            "&sec=" +
            this.myObj.secID +
            "&subID=" +
            item.marksGrid.subjectID +
            "&examTypeID=" +
            this.myObj.examID +
            "&sessionID=" +
            this.myObj.sessionID +
            "&isGraded=false&date=" +
            item.date +
            "&marksGridID=" +
            item.marksGrid.id,
          context: this,
          token: this.$store.state.userData.token,
        });
        // console.log(this.marksData);
        this.items = this.marksData.data;
        if (this.items[0].obj.isGraded == true) this.enterGrade = true;
        else this.enterGrade = false;

        this.newData = this.marksData.data;
        this.marksData.marksGrid = item.marksGrid;
        // console.log(this.items);
        this.gridLoading = false;
        this.sortMarks();
        this.clikced = 1;
        setTimeout(() => {
          this.firstFocus();
        }, 500);
        // this.marksObj = this.items.map((el) => {
        //   // return { obtained_marks: el.marks };
        //   return { obtained_marks: 0, att: true };
        // });
        // console.log(this.marksObj);
      } else {
        this.$bvToast.toast("Please select all the requird fields", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
        this.clikced = 1;
      }
    },
    async LoadAttMarks() {
      let x = parseInt(this.marksData.totalMarks);
      if (isNaN(x) || x <= 0) this.marksData.totalMarks = 20;
      else this.marksData.totalMarks = x;
      // console.log(this.marksData);

      let result = await Swal.fire({
        title: "Are you sure?",
        text:
          "Do you want to add attendance marks for total of " +
          this.marksData.totalMarks +
          "?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes",
      });
      if (result.isConfirmed) {
        this.attLoading = true;
        let obj = {
          classID: this.marksData.clsID,
          secID: this.marksData.sec,
          examID: this.marksData.examTypeID,
          sessionID: this.marksData.sessionID,
          subjectID: this.marksData.subID,
          totalMarks: this.marksData.totalMarks,
          campusID: this.marksData.campusID,
        };
        // console.log(obj);
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Marks/LoadAttendanceMarks?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: obj,
          message: "Attendance marks added successfully.",
          context: this,
          attMarks: true,
          token: this.$store.state.userData.token,
        });
        // console.log(status);
        if (status.marksGridID) {
          this.attMarksAfterSave(obj, status.marksGridID);
        }

        this.attLoading = false;
      }
    },
    async attMarksAfterSave(item, mgID) {
      // console.log(item);
      this.gridLoading = true;
      this.newEntry = false;

      this.currentSub = this.allSubjects.find(
        (el) => el.id == item.subjectID
      ).subject;
      this.currentExam = this.examType.find(
        (el) => el.id == item.examID
      ).examType;
      this.currentSession = this.sessions.find(
        (el) => el.id == item.sessionID
      ).session;

      let temp = this.classes.find((el) => el.cls.id == item.classID);
      this.currentClass = temp.cls.name;
      this.sectionName = temp.sections.find(
        (el) => el.id == item.secID
      ).section;

      this.marksData = await this.get({
        url:
          this.$store.state.domain +
          "Marks/LoadMarksData?db=" +
          this.$store.state.userData.db +
          "&campusID=" +
          this.$store.state.userData.cId +
          "&clsID=" +
          item.classID +
          "&sec=" +
          item.secID +
          "&subID=" +
          item.subjectID +
          "&examTypeID=" +
          item.examID +
          "&sessionID=" +
          item.sessionID +
          "&isGraded=false&date=" +
          new Date().toJSON().split("T")[0] +
          "&marksGridID=" +
          mgID,
        context: this,
        token: this.$store.state.userData.token,
      });
      // console.log(this.marksData);
      this.items = this.marksData.data;
      if (this.items[0].obj.isGraded == true) this.enterGrade = true;
      else this.enterGrade = false;

      this.newData = this.marksData.data;
      this.marksData.marksGrid = {
        id: mgID,
        campusID: this.marksData.campusID,
        cID: this.marksData.clsID,
        secID: this.marksData.sec,
        subjectID: this.marksData.subID,
        examID: this.marksData.examTypeID,
        sessionID: this.marksData.sessionID,
        tID: this.marksData.tID,
        topicID: this.marksData.topicID,
        chapterID: this.marksData.chapterID,
        date: this.marksData.date,
      };

      // console.log(this.items);
      this.gridLoading = false;
      this.sortMarks();
      this.clikced = 1;
      setTimeout(() => {
        this.firstFocus();
      }, 500);
    },
    async loadMissing() {
      this.gridLoading = true;
      let data = await this.get({
        url:
          this.$store.state.domain +
          "Marks/LoadMissingStudents?db=" +
          this.$store.state.userData.db +
          "&campusID=" +
          this.$store.state.userData.cId +
          "&clsID=" +
          this.recordObj.marksGrid.cID +
          "&sec=" +
          this.recordObj.marksGrid.secID +
          "&subID=" +
          this.recordObj.marksGrid.subjectID +
          "&examTypeID=" +
          this.recordObj.marksGrid.examID +
          "&sessionID=" +
          this.recordObj.marksGrid.sessionID +
          "&isGraded=false&date=" +
          this.recordObj.marksGrid.date +
          "&marksGridID=" +
          this.recordObj.marksGrid.id,
        context: this,
        token: this.$store.state.userData.token,
      });
      if (data == "added" || data == "Added") this.show(this.recordObj);
      else this.gridLoading = false;
    },
    firstFocus() {
      let first = document.querySelector(".marks-field");
      first.focus();
      first.select();
    },
    openSettings() {
      this.visibility2 = true;
    },
    CheckTemp() {
      var elem = this.$refs["temp"];
      if (this.temp == 0) {
        return (elem.state = true);
      } else {
        return (elem.state = false);
      }
    },
    setMessage() {
      let result = this.tempOptions.find((el) => el.id == this.temp);
      this.tempMessage = result.message;
      console.log(result);
    },
    saveSetting() {
      if (this.CheckTemp() == true) {
        //   var status = await this.post({
        //     url:
        //       this.$store.state.domain +
        //       " ?db=" +
        //       this.$store.state.userData.db,
        //     body: this.bonusObj,
        //     message: "Bonus added successfully.",
        //     context: this,
        //     token: this.$store.state.userData.token,
        //   });
        //   this.request = false;
        //   this.visibility = false;
        //   if (status) this.LoadData();
      }
    },
    checkExam() {
      var elem = this.$refs["bon_exam"];
      if (this.bonusObj.examID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckType() {
      var elem = this.$refs["bon_type"];
      if (this.bonusObj.type == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async Add() {
      this.CheckType();
      this.checkExam();

      if (this.CheckType() == false || this.checkExam() == false) {
        return this.$bvToast.toast("Please enter the details properly", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        if (this.bonusObj.id == 0) {
          //Add
          this.request = true;
          console.log("obj:", this.bonusObj);

          //   var status = await this.post({
          //     url:
          //       this.$store.state.domain +
          //       " ?db=" +
          //       this.$store.state.userData.db,
          //     body: this.bonusObj,
          //     message: "Bonus added successfully.",
          //     context: this,
          //     token: this.$store.state.userData.token,
          //   });
          //   this.request = false;
          //   this.visibility = false;
          //   if (status) this.LoadData();
        } else {
          //Edit
          this.request = true;
          console.log("edit_obj:", this.bonusObj);
          this.request = false;
          this.visibility = false;
          //   var status = await this.put({
          //     url:
          //       this.$store.state.domain +
          //       " /" +
          //       this.bonusObj.id +
          //       "?db=" +
          //       this.$store.state.userData.db,
          //     body: this.bonusObj,
          //     message: "Bonus updated successfully.",
          //     context: this,
          //     token: this.$store.state.userData.token,
          //   });
          //   this.request = false;
          //   this.visibility = false;
          //   console.log("status", status);
          //   if (status) this.LoadData();
        }
      }
    },

    async Delete(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });
      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "Marks/DeleteMarksGrid?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&marksGridID=" +
            id,
          message: "Marks removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.loadRecords();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
/* .change-bg {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
} */
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
</style>
